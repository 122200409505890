import { Container, Row, Col } from "react-bootstrap";
import { Newsletter } from "./Newsletter";
import logo from "../assets/img/emo-logo.png";
import { FaTelegramPlane, FaTiktok } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Newsletter />
          <Col size={12} md={6} className="text-md-start text-center mb-3">
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} md={6} className="text-center text-md-end">
            <div className="social-icon">
              <a href="https://t.me/emoai_sol"><FaTelegramPlane /></a>
              <a href="https://x.com/EMO_AI_Robot"><FaXTwitter /></a>
            </div>
            <p>Copyright 2025. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
